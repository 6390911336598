
import { Component, Vue } from 'vue-property-decorator'
import NotificationSendList from '@/components/NotificationSendList.vue'

@Component({
  components: {
    NotificationSendList
  }
})
export default class NotificationSend extends Vue {}


import { Component, Vue, Prop } from 'vue-property-decorator'
import NotificationRuleHistoryList from '@/components/NotificationRuleHistoryList.vue'

@Component({
  components: {
    NotificationRuleHistoryList
  },
})
export default class NotificationRuleHistory extends Vue {
  @Prop() public id!: string
}
